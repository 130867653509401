<template>
  <sign-page
    title-text="员工总览"
    :request="request"
    table-size="large"
    :dealFormData="dealFormData"
    :titleMenus="[{key: 'batch', label: '批量维护'}, {key: 'add', label: '新增'}]"
    @clickTitleMenu="clickTitleMenu"
    :column-list="columnList"
    :search-parms="searchParms"
    :form-parms-update="formParms"
    :form-parms-add="formParms"
    :table-actions-fixed="true"
    :dealSearchParm="dealSearchParm"
    :initSearchData="{month: $datetime.format(new Date(), 'Y-M')}"
    :tableActionsWidth="100"
    :need-data-file="true">
    <wage-form
      @dataUpdate="dataUpdate"
      :jobTitleTypeList="$store.getters.selectDatas['job_title_type']"
      :wageJobTitleList="wageJobTitles"
      :workerList="workers"
      :wageLevelList="wageLeves"
      ref="wafeForm"
      title="批量调整"></wage-form>
  </sign-page>
</template>

<script>
import {
  workerWageRequest as request,
  performanceWorkerRequest,
  wageJobTitleRequest,
  wageLevelRequest
} from '../../api'

import WageForm from './cmp/form'

export default {
  components: {
    WageForm
  },
  computed: {
    searchParms () {
      return [{
        type: 'monthPicker',
        placeholder: '月份',
        key: 'month',
        default: new Date()
      }]
    },
    formParms: {
      get () {
        let data = [{
          type: 'monthPicker',
          label: '月份',
          key: 'month',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '员工',
          selectDatas: this.workers,
          key: 'performanceWorkerId',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '岗位类型',
          selectDatas: this.$store.getters.selectDatas['job_title_type'],
          key: 'jobTitleType'
        },
        {
          type: 'select',
          label: '岗位等级',
          getSelectDatas: (data) => {
            return this.wageJobTitles.filter(v => data.jobTitleType && v.data.jobTitleType === data.jobTitleType)
          },
          key: 'wageJobTitleId'
        },
        {
          type: 'select',
          getSelectDatas: (data) => {
            return this.wageLeves.filter(v => data.jobTitleType && v.data.jobTitleType === data.jobTitleType)
          },
          label: '薪级等级',
          key: 'wageLevelId'
        },
        {
          type: 'input',
          label: '补贴工资',
          key: 'subsidy',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '员工姓名',
          sort: true,
          field: 'workerName'
        },
        {
          title: '身份证号',
          sort: true,
          field: 'idNo'
        },
        {
          title: '岗位类型',
          sort: true,
          field: 'jobTitleType'
        },
        {
          title: '岗位等级',
          sort: true,
          field: 'wageJobTitleTitle'
        },
        {
          title: '薪级等级',
          sort: true,
          field: 'wageLevelLevel'
        },
        {
          title: '补贴工资',
          sort: true,
          field: 'subsidy'
        }]
        return data
      }
    }
  },
  created () {
    this.loadWorker()
    this.loadJobTitle()
    this.loadWageLevel()
  },
  methods: {
    dataUpdate () {
      this.pageVm.loadData()
    },
    clickTitleMenu (key, pageVm) {
      this.pageVm = pageVm
      if (key === 'batch') {
        this.$refs.wafeForm.open()
      }
    },
    async loadWorker () {
      let datas = await performanceWorkerRequest.get({})
      this.workers = datas.map(v => {
        return {
          key: v.id,
          label: v.name,
          data: v
        }
      })
    },
    async loadJobTitle () {
      let datas = await wageJobTitleRequest.get({})
      this.wageJobTitles = datas.map(v => {
        return {
          key: v.id,
          label: v.jobTitleType + ' ' + (v.mLevel ? v.mLevel + ' ' : '') + v.title,
          data: v
        }
      })
    },
    async loadWageLevel () {
      let datas = await wageLevelRequest.get({})
      this.wageLeves = datas.map(v => {
        return {
          key: v.id,
          label: v.jobTitleType + ' ' + v.name,
          data: v
        }
      })
    },
    dealSearchParm (parm) {
      if (parm) {
        parm.month = parm.month && parm.month instanceof Date ? this.$datetime.format(parm.month, 'Y-M') : parm.month
      }
      return parm
    },
    dealFormData (parm) {
      parm.month = parm.month && parm.month instanceof Date ? this.$datetime.format(parm.month, 'Y-M') : parm.month
      return parm
    }
  },
  data () {
    return {
      workers: [],
      wageJobTitles: [],
      wageLeves: [],
      pageVm: null,
      request: request
    }
  }
}
</script>
